import $ from "jquery";

const marketplaceUrl = "https://marketplace.pepsico.digital";

/**
 * Generates url to api for product by id
 * @param {number} id
 */
function getMarketplaceApiUrlForId(id) {
  return `${marketplaceUrl}/api/product/${id}`;
}

/**
 * Renders contents for retailers popup slider
 * @param {Array|null} retailers
 * @param {number} blockLength
 */
function renderSliderContents(retailers, blockLength = 4) {
  const wrapper = $(".swiper-wrapper.sliderShop__inner");
  const empty = $(".sliderShop__empty");

  if (retailers === null) {
    $(".swiper-wrapper.sliderShop__inner .sliderShop__items").remove();
    return;
  }

  if (retailers.length === 0) {
    empty.show();
  } else {
    empty.hide();
  }

  // split to chunks
  var retailersBlocks = [];
  for (var i = 0; i < retailers.length; i += blockLength) {
    retailersBlocks.push(retailers.slice(i, i + blockLength));
  }

  const navigation = $(".swiper-pagination, .sliderShop__arrows");

  if (retailersBlocks.length > 1) {
    navigation.show();
  } else {
    navigation.hide();
  }

  // render
  retailersBlocks.forEach((block) => {
    const blockDiv = $("<div>", {
      class: "swiper-slide sliderShop__items",
    });
    block.forEach((retailer) => {
      const inStock = retailer.inStock > 0;
      blockDiv.append(
        $("<div>", {
          class: "sliderShop__item",
        })
          .append(
            $("<a>", {
              href: retailer.url,
              target: "_blank",
            }).append(
              $("<img>", {
                src: retailer.image,
                alt: retailer.name,
              })
            )
          )
          .append(
            $("<h3>", {
              text: retailer.name,
            })
          )
          .append(
            $("<div>", {
              class: "sliderShop__status " + (inStock ? "yes" : "not"),
              text: inStock ? "Есть в наличии" : "Нет в наличии",
            })
          )
          .append(
            $("<a>", {
              class: "button",
              href: retailer.url,
              target: "_blank",
              text: "купить",
            })
          )
      );
    });

    wrapper.append(blockDiv);
  });
}

export default function () {
  $(".but_buy, .but_buy_mobile").on("click", () => {
    // purchase buttons action
    const marketplaceId = $(".carousel-cell.is-selected").attr(
      "data-marketplace-id"
    );

    const loading = $(".sliderShop__loading");
    const error = $(".sliderShop__error");
    renderSliderContents(null);
    loading.show();
    error.hide();

    $.get(getMarketplaceApiUrlForId(marketplaceId), (data) => {
      try {
        const retailers = data.retailers.map((retailer) => {
          return {
            name: retailer.name,
            image: marketplaceUrl + retailer.retail.image,
            price: retailer.price,
            url: retailer.url,
            inStock: retailer.in_stock,
            sort: retailer.sort,
          };
        });

        retailers.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        renderSliderContents(retailers, 4);
      } catch (error) {
        error.show();
      }
    })
      .always(() => {
        loading.hide();
      })
      .fail(() => {
        error.show();
      });
  });
}
