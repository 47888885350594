import $ from "jquery";
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/all';
import productsAvailabilityLoadInit from './import/productsAvailabilityLoad';

gsap.registerPlugin(ScrollTrigger);

import debounce from "lodash";

// import Flickity from "flickity";
import Flickity from "flickity-fade";

import lozad from "lozad";
import "./import/js-cloudimage-360-view/dist/index";
window.CI360.notInitOnLoad = true;

// 360
window.onload = function () {
  document.body.classList.add("loaded");
  document.body.classList.add("domloaded");
  // window.CI360.init();
  // setTimeout(() => {
  //   window.CI360.init();
  // }, 2000);
};

const observer = lozad();
observer.observe();

// Rellax
import Rellax from "rellax";

// AOS
import AOS from "aos";

import "./import/modules";
import "./import/components";

// Scroll To Link
import "./import/jsscrollto";

// player
import Plyr from "plyr";
const player = new Plyr("#player");
// document.querySelector(".bvideo").addEventListener("click", function() {
//   console.log("click video", player.playing, player.paused);
//   if(player.paused) {
//     player.play();
//   } else {
//     player.pause();
//   }
// });

let openMenuBtn = document.querySelector(".js-open-menu");
let menuParent = document.querySelector(".js-menu");

openMenuBtn.addEventListener("click", function () {
  document.body.classList.toggle("block-scroll");
  menuParent.classList.toggle("active");
});


let playVideo = document.querySelector(".js-play-video");
if (playVideo) {
  playVideo.addEventListener("click", () => {
    let ytVideo = document.getElementById("ytVideo");
    ytVideo.src += "&autoplay=1";
    ytVideo.parentElement.classList.add("play");
  });
}


if(document.querySelector(".js-slider")) {
  let flkty = new Flickity(".js-slider", {
    draggable: false,
    fade: true,
    adaptiveHeight: true,
    wrapAround: true
  });

  flkty.on("change", index => {
    activateBtnIndex(index + 1, allTabBtn);
  });


  let allTabBtn = [].slice.call(document.querySelectorAll("[data-slid-btn]"));
  if (allTabBtn.length) {
    allTabBtn.forEach(item => {

      // Event Click
      item.addEventListener("click", e => {
        let target = e.currentTarget;
        let index = target.dataset.slidIndex;
        flkty.select(index - 1);
      });
    });
  }

  [].slice.call(document.querySelectorAll(".js-next")).forEach(item => {
    item.addEventListener("click", function () {
      flkty.next();
    });
  });

  [].slice.call(document.querySelectorAll(".js-prev")).forEach(item => {
    item.addEventListener("click", function () {
      flkty.previous();
    });
  });

  (function () {
    flkty.resize();
    let timeFlag = 0;

    let interva360 = setInterval(() => {
      // console.log("interval in");
      flkty.resize();
      // console.log(timeFlag);
      if (timeFlag > 100) {
        clearInterval(interva360);
      }
      timeFlag++;
    }, 100);
  })();

  setTimeout(() => {
    flkty.resize();
  }, 1000);

  setTimeout(() => {
    flkty.resize();
  }, 2000);
}

function activateBtnIndex(index, allBtn = []) {
  let btnIndex = [].slice.call(document.querySelectorAll("[data-slid-active*='|" + index + "|']"));
  // console.log("call activateBtnIndex", btnIndex);

  allBtn.forEach(item => item.classList.remove("active"));
  // if(btnIndex.length) {
  // }

  activateSubmenu(null);
  btnIndex.forEach(item => {
    // console.log(item.closest(".submenu"));
    activateSubmenu(item.closest(".submenu"));
    item.classList.add("active");
  });

  console.log(index);
  updateSlide(index);
}

function activateSubmenu(item) {
  let submenu = [].slice.call(document.querySelectorAll(".submenu"));

  submenu.forEach(item2 => item2.classList.remove("was-active"));
  submenu.forEach(item2 => item2.classList.remove("active"));
  submenu.forEach((el) => {
    if (el === item) {
      el.classList.add("active");
    }
  });
}


// =========================
let jsScrollDown = document.querySelector(".js-down");
let gamefuel = document.querySelector(".gamefuel");

if(jsScrollDown) {
  jsScrollDown.addEventListener("click", () => {
    if (gamefuel) {
      gamefuel.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }
  });
}


// animate
let rellax = new Rellax(".rellax", {
  breakpoints: [992, 992, 1201]
});

AOS.init();

// Scroll To
$(".menu__nav").activescroll({
  scroll: "scroll",
  active: "active",
  offset: 80,
  animate: 1000,
  callback: function () {
    document.querySelector(".menu").classList.remove("active");
    document.body.classList.remove("block-scroll");
  }
});

// Btn Top
function scrollToTop (duration) {
  // cancel if already on top
  if (document.scrollingElement.scrollTop === 0) return;

  const cosParameter = document.scrollingElement.scrollTop / 2;
  let scrollCount = 0, oldTimestamp = null;

  function step (newTimestamp) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
      if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
      document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

let btntop = document.querySelector(".buttonup");
if(btntop) {
  document.addEventListener("scroll", () => {
    if(window.scrollY > 200) {
      btntop.classList.add("active");
    } else {
      btntop.classList.remove("active");
    }
  }, {passive: true});

  btntop.addEventListener("click", () => {
    scrollToTop(1000);
  });
}

function updateSlide(index) {

	var slides = {
		slide1: {
			h2: "Adrenaline Rush",
			composition: "кофеин, экстракт гуараны и женьшеня, таурин, витамины группы В, L-карнитин.",
			cofeSKU: "Adrenaline Rush............75 мг",
			sugarSKU: "Adrenaline Rush..........30,5 мг",
		},
		slide2: {
			h2: "Adrenaline Rush",
			composition: "кофеин, экстракт гуараны и женьшеня, таурин, витамины группы В, L-карнитин.",
			cofeSKU: "Adrenaline Rush............75 мг",
			sugarSKU: "Adrenaline Rush..........30,5 мг",
		},
		slide3: {
			h2: "Adrenaline Rush",
			composition: "кофеин, экстракт гуараны и женьшеня, таурин, витамины группы В, L-карнитин.",
			cofeSKU: "Adrenaline Rush............75 мг",
			sugarSKU: "Adrenaline Rush..........30,5 мг",
		},
		slide4: {
			h2: "Adrenaline Ягодная Энергия",
			composition: "кофеин,  экстракт гуараны и  женьшеня, L-карнитин, таурин, витамины группы В.",
			cofeSKU: "Adrenaline Flavors.........75 мг",
			sugarSKU: "Adrenaline Flavors.......29,5 мг",
		},
		slide5: {
			h2: "Adrenaline Апельсиновая Энергия",
			composition: "кофеин,  экстракт гуараны и  женьшеня, L-карнитин, таурин, витамины группы В.",
			cofeSKU: "Adrenaline Flavors.........75 мг",
			sugarSKU: "Adrenaline Flavors.......29,5 мг",
		},
		slide6: {
			h2: "Game Fuel Charged Candy Crush",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Game Fuel.....................75 мг",
			sugarSKU: "Game Fuel.................26,25 мг",
		},
		slide7: {
			h2: "Game Fuel Charged Candy Crush",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Game Fuel.....................75 мг",
			sugarSKU: "Game Fuel.................26,25 мг",
		},
		slide8: {
			h2: "Game Fuel Charged Candy Crush",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Game Fuel.....................75 мг",
			sugarSKU: "Game Fuel.................26,25 мг",
		},
		slide9: {
			h2: "Game Fuel Charged Lime-Ginger Blast",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Game Fuel.....................75 мг",
			sugarSKU: "Game Fuel.................26,25 мг",
		},
		slide10: {
			h2: "Game Fuel Charged Lime-Ginger Blast",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Game Fuel.....................75 мг",
			sugarSKU: "Game Fuel.................26,25 мг",
		},
		slide11: {
			h2: "Adrenaline Black Gold",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Adrenaline Black Gold....75 мг",
			sugarSKU: "Adrenaline Black Gold....17 мг",
		},
		slide12: {
			h2: "Adrenaline White Gold",
			composition: "кофеин, экстракт женьшеня, таурин, витамины группы В.",
			cofeSKU: "Adrenaline White Gold....75 мг",
			sugarSKU: "Adrenaline White Gold...6,5 мг",
		},
		slide13: {
			h2: "Adrenaline Red Gold",
			composition: "кофеин, витамины группы В.",
			cofeSKU: "Adrenaline Red Gold.......75 мг",
			sugarSKU: "Adrenaline Red Gold.......17 мг",
		},
		slide14: {
			h2: "Adrenaline Rush Non Sugar",
			composition: "кофеин, экстракт гуараны и женьшеня, таурин, витамины группы В, L-карнитин.",
			cofeSKU: "Non Sugar......................75 мг",
			sugarSKU: "Non Sugar....................0,07 мг",
		},
	};

//	if(slides['slide'+index].h2)
//		$('#adrenalineBlockH2').text('В '+slides['slide'+index].h2+' содержатся:').attr('data-text', 'В '+slides['slide'+index].h2+' содержатся:');

	if(slides['slide'+index].composition)
		$('#adrenalineBlockComposition').text(slides['slide'+index].composition);

	if(slides['slide'+index].cofeSKU)
		$('#adrenalineBlockCofeSKU').text(slides['slide'+index].cofeSKU);

	if(slides['slide'+index].sugarSKU)
		$('#adrenalineBlockSugarSKU').text(slides['slide'+index].sugarSKU);
	
	if(window.adrenaline.slides.length > 1) {
		window.adrenaline.removeSlide([2,3,4,5]); // numeration from 0
		if(index == 1 || index == 2 || index == 3 || index == 4 || index == 5 || index == 14) {
			window.adrenaline.appendSlide('<div class="swiper-slide adrenalineSlider__item">'+window.slide3+'</div>');
			window.adrenaline.appendSlide('<div class="swiper-slide adrenalineSlider__item">'+window.slide4+'</div>');
			window.adrenaline.appendSlide('<div class="swiper-slide adrenalineSlider__item">'+window.slide5+'</div>');
		}
		if(index == 6 || index == 7 || index == 8 || index == 9 || index == 10 || index == 11 || index == 12 || index == 13) {
			window.adrenaline.appendSlide('<div class="swiper-slide adrenalineSlider__item">'+window.slide3+'</div>');
			window.adrenaline.appendSlide('<div class="swiper-slide adrenalineSlider__item">'+window.slide4+'</div>');
		}
		if(index == 6 || index == 7 || index == 8 || index == 9 || index == 10 || index == 11 || index == 12) {
			window.adrenaline.appendSlide('<div class="swiper-slide adrenalineSlider__item">'+window.slide6+'</div>');
		}
		window.adrenaline.slideTo(0, 0);
	}
}

$(document).ready( function() {

	window.slide1 = $('#slide1').html();
	window.slide2 = $('#slide2').html();
	window.slide3 = $('#slide3').html();
	window.slide4 = $('#slide4').html();
	window.slide5 = $('#slide5').html();
	window.slide6 = $('#slide6').html();

	window.ga = ga;

	$(document).on('click', '.ga-click', function() {
		if($(this).data('ga-label'))
			ga('send', 'event', $(this).data('ga-category'), $(this).data('ga-action'), $(this).data('ga-label'));
		else
			ga('send', 'event', $(this).data('ga-category'), $(this).data('ga-action'));
	});


  $(document).on("click", "canvas", function() {
    $(this).closest(".slider__360").addClass("canvas-clicked");
  });

  $(document).on("mouseover", ".bslider__wrap .tabs > ul > li", function() {
    $(this).closest(".tabs").find(".submenu.active").addClass("was-active").removeClass("active");
  });

  $(document).on("mouseout", ".bslider__wrap .tabs > ul > li", function() {
    $(this).closest(".tabs").find(".submenu.was-active").addClass("active").removeClass("was-active");
  });


  // Все для сладера в попап swiper

let quest = document.querySelector('.adrenalineInfo__quest');
let questInfo = document.querySelector('.adrenalineBlock');
let heightProducts = document.querySelector('#products');

quest.addEventListener('click', function(){
    this.classList.toggle('active');
    questInfo.classList.toggle('active');

    if (this.classList.contains('active')) {
//      heightProducts.style.height = '1900px';
    }else {
//      heightProducts.style.height = 'auto';
    }

},false)


let swiper = new Swiper('.sliderShop', {
    observer: true,
    spaceBetween: 30,
    observeSlideChildren: true,
    observeParents: true,
    updateOnWindowResize: true,
    navigation: {
        nextEl: '.sliderShop__arrow-next',
        prevEl: '.sliderShop__arrow-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    mousewheel: true,
    keyboard: true
});

const adrenaline = new Swiper('.adrenalineSlider', {
    observer: true,
    observeSlideChildren: true,
    autoHeight: true,
    slidesPerView: 1,
    centeredSlides: true,
    observeParents: true,
    navigation: {
        nextEl: '.adrenalineSlider__next',
        prevEl: '.adrenalineSlider__prev',
    },
    pagination: {
        el: '.adrenalineSlider-pagination',
        clickable: true,
    },
    mousewheel: true,
    keyboard: true,
});

window.adrenaline = adrenaline;

//setInterval(function() { console.log(window.adrenaline.slides); }, 1000);

var adrItems = document.querySelectorAll('.adrenalineFilling__item');
adrItems.forEach(function(item,index) {
    adrenaline.on('activeIndexChange',function(){
        if (adrenaline.realIndex === 0 && index === 0) {
            item.classList.add('active');
        } else if (adrenaline.realIndex === 1 && index === 1){
            item.classList.add('active');
        } else {
            item.classList.remove('active');
        }
    })
})

  const textsUp = document.querySelectorAll('.text-up');

  for(let i=0; i<textsUp.length; i++) {

    if(textsUp[i].classList.contains('text-up--mob-only')&&window.innerWidth>767){
      gsap.to(textsUp[i], {
        '--top-translate': 0,
        duration: 0,
      })
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: textsUp[i],
        start: i===0||i===1?'bottom bottom':'center bottom'
      },
    });

    if(textsUp[i].classList.contains('text-up--mob-only')){
      if(window.innerWidth<768){
        tl
            .to(textsUp[i], {
              '--top-translate': 0,
              scaleY: 1,
              duration: i===0||i===1?0.5:1.2,
              delay: textsUp[i].classList.contains('text-up--head')?0.1:0
            });
      }
    }
    else{
      tl
          .to(textsUp[i], {
            '--top-translate': 0,
            scaleY: 1,
            duration: i===0||i===1?0.5:1.2,
            delay: textsUp[i].classList.contains('text-up--head')?0.1:0
          });
    }



  }

});

setTimeout(function() {
	ga('send', 'event', 'Timer', '20s', 'Ok');
}, 20000);

setInterval(function() {
	if(window.twist) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({'event': 'adrush_custom_twist'});
		window.twist = 0;
	}
}, 2000);

productsAvailabilityLoadInit();